import { FactoryIcon, GlobeIcon, MapPinIcon } from "lucide-react";

export default function ServiceSection() {
  const services = [
    {
      title: "Cross-border Technology Partnerships",
      text: "Novaa will partner with leading technology companies in the rechargeable battery supply chain to bring these products and technologies to India",
      icon: <GlobeIcon size={50} color="black" />,
    },
    {
      title: "Access to the Indian market",
      text: "Novaa will tie up with select global technology leaders and help them in building a presence in India and access Indian customers",
      icon: <MapPinIcon size={50} color="black" />,
    },
    {
      title: "Manufacturing localization",
      text: "Novaa plans to bring cutting edge manufacturing technology in the rechargeable battery supply chain to India for meeting domestic as well as global demand",
      icon: <FactoryIcon  size={50} color="black" />,
    },
  ];

  return (
    <section id="service" className="bg-gray-100 py-10 md:py-16">
      <div className="container mx-auto px-4">
      <div className="pb-5">
            <h1 className="text-black text-[60px] md:text-[80px] font-extrabold text-center">Our <span className="text-blue-400">Service</span></h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center text-center transition-transform transform hover:scale-105 hover:shadow-xl">
              {service.icon}
              <h3 className="text-xl font-semibold text-[#000000] mt-4 mb-2">{service.title}</h3>
              <p className="text-muted-foreground text-black">{service.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}