import './App.css';
import HeroSection from './components/HeroSection';
import OurTeam from './components/OurTeam';
import ServiceSection from './components/ServicesSection';
// import AboutUs from './components/AboutUs';

function App() {
  return (
    <>
    <HeroSection />
    <OurTeam />
    <ServiceSection />
    </>
  );
}

export default App;
