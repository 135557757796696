import { Mail, MapPin } from "lucide-react"

const contactInfo = [
  {
    icon: <Mail className="h-5 w-5 text-primary" />,
    text: "abhinav@novaa.co.in",
    href: "mailto:info@lithiumtech.com",
  },
  {
    icon: <MapPin className="h-5 w-5 text-primary" />,
    text: "2nd Floor, Mahajan Mills Compound, LBS Marg, opp. Mahindra Showroom, Vikhroli West, Mumbai 400079",
  },
]

export default function ResponsiveFooter() {
  return (
    <footer className="bg-gradient-to-r from-gray-900 to-gray-800 text-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-16">
          <div className="space-y-6">
            <h2 className="text-3xl font-bold text-primary">Novaa-Energy</h2>
            <p className="text-gray-300 text-sm max-w-md">
              Powering the future with advanced lithium battery technology. Sustainable energy solutions for a brighter tomorrow.
            </p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-6 text-primary">Contact</h3>
            <ul className="space-y-4">
              {contactInfo.map(({ icon, text, href }, index) => (
                <li key={index} className="flex items-start">
                  <span className="mt-1 mr-3">{icon}</span>
                  {href ? (
                    <a
                      href={href}
                      className="text-gray-300 hover:text-white transition-colors duration-300 hover:underline"
                    >
                      {text}
                    </a>
                  ) : (
                    <span className="text-gray-300">{text}</span>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="mt-12 pt-8 border-t border-gray-700">
          <p className="text-center text-gray-400 text-sm">
            &copy; {new Date().getFullYear()} main Novaa, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}