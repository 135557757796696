import React from "react"
import { Linkedin } from "lucide-react"

const teamMembers = [
  {
    id: 1,
    name: "Vipin Pathak",
    description: "Mumbai",
    image: "/Team-1.jpg",
    userName: "vipin-pathak",
    link: "https://www.linkedin.com/in/vipin-pathak/",
  },
  {
    id: 2,
    name: "Shashank Kohli",
    description: "Singapore",
    image: "/Team-2.png",
    userName: "shashank-kohli-1437341b",
    link: "https://www.linkedin.com/in/shashank-kohli-1437341b/",
  },
]

function OurTeam() {
  return (
    <section id="team" className="bg-white py-10 md:py-16">
      <div className="container mx-auto px-4">
        <div className="pb-5 text-center">
          <h1 className="text-black text-[60px] md:text-[80px] font-extrabold">
            Our <span className="text-blue-400">Team</span>
          </h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 justify-items-center">
          {teamMembers.map((member) => (
            <div
              key={member.id}
              className="max-w-sm w-full bg-white border border-gray-200 rounded-lg shadow transition-all duration-300 hover:shadow-lg"
            >
              <img
                className="rounded-t-lg w-full h-64 object-contain img-fl object-center"
                src={member.image}
                alt={member.name}
              />
              <div className="p-5 text-center">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                  {member.name}
                </h5>
                <p className="mb-3 font-normal text-gray-700">
                  {member.description}
                </p>
                <a
                  href={member.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center space-x-2 text-blue-600 hover:text-blue-800 transition-colors duration-200"
                >
                  <Linkedin className="w-5 h-5" />
                  <span className="text-sm font-medium">{member.userName}</span>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default OurTeam