import React from "react";

function HeroSection() {
  return (
    <div
      id="home"
      className="relative h-screen bg-cover bg-center"
      style={{ backgroundImage: "url('/hero.jpg')" }}
    >
      <div className="flex items-center justify-center h-full bg-black bg-opacity-50">
        <h1 className="text-white text-[60px] md:text-[80px] lg:text-[100px] font-bold text-center px-4">
          Bringing latest rechargeable battery technology to India!
        </h1>
      </div>
    </div>
  );
}

export default HeroSection;
